@tailwind base;
@tailwind components;


html, body {
  width: 100%;
  height: 100%;
}
.bg {
  background-image: linear-gradient(#fff 160px, #475C97 160px, #2F437C 400px, #DAD8D5  400px);
  background-size: 100%;
  background-repeat: repeat-x;    
  background-color: #DAD8D5;
}
@media (min-width: 768px) {
  .bg {
    background-image: linear-gradient(#fff 192px, #475C97 192px, #2F437C 576px, #DAD8D5  576px);
  }
}

.services {
  height: 240px;
}

@media (max-width: 767px) {
  .text-shadow {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  }
  .tan-gradient {
    background-image: radial-gradient(50% 150%, #DAD8D5 50%, #C6C1BE 77%, #AFA7A4 100%);
  }
}

.cta {
  @apply text-white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#B1D085, #85B63E 2%, #5BA00D 98%, #63A816);
  text-shadow: 0 1px 1px #488107;
}

@media (max-width: 767px) {
  .cta {
    @apply cursor-pointer select-none py-2 px-8 rounded-lg shadow-lg text-2xl;
  }
}

@media (min-width: 768px) {
  .cta {
    @apply h-20 mt-4 text-center text-3xl rounded-t-lg;
  }
}

.divider {
  border-color: inherit;
  border-width: 1px 0;
  border-style: solid;
  border-bottom-color: rgba(255,255,255,0.2);
}

.form-group {
  @apply flex flex-col mb-4 relative;
}

.text-input {
  @apply border px-2 py-1;
  border-color: theme('colors.gray.light');
}
.form-error {
  @apply text-xs text-red;
}

.checkbox {
  border-color: theme('colors.gray.light');
}

.submit {
  background: linear-gradient(#B1D085, #85B63E 2%, #5BA00D 98%, #63A816);
  text-shadow: 0 1px 1px #488107;
}

@tailwind utilities;